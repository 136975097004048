<template>
  <div>
    <el-autocomplete
      v-model="currentValue"
      :placeholder="placeholder"
      :disabled="disabled"
      :label="itemKey"
      :value-key="itemKey"
      :fetch-suggestions="querySearchAsync"
      @select="handleSelect"
      @change="emitValue(currentValue)"
    >
      <i
        class="el-icon-edit el-input__icon"
        slot="suffix">
      </i>
    </el-autocomplete>
  </div>
</template>

<script>
  export default {
    name: 'comAutocomplete',
    props:{
      placeholder: String,
      disabled: Boolean,
      value: [String, Number],
      tableName: String,
      itemKey: String,
      /*
      * 展示类型
      * search =>  页面查询数据，作为查询条件时
      * info => 数据增删改查详情数据时
      * */
      showType:{
        type: String
      }
    },
    data() {
      return {
        currentValue:'',
        model:null,
      }
    },
    watch:{
      value(val){
        if(this.currentValue !== val){
          this.currentValue = this.value
        }
      }
    },
    computed:{
      getDataMethod(){
         return this.tableName
      }
    },
    mounted() {
      this.$nextTick(()=>{
        this.init();
      })
    },
    methods: {
      init(){
        this.currentValue = this.value
      },
      /*
      * 获取待选的数据
      * */
      async querySearchAsync(queryString, cb){
        let result = []
        if(!this.tableName) return cb(result)
        const params = {
          key:this.itemKey,
          value:this.currentValue
        }
        const {status,data} = await this.$store.dispatch(this.tableName,params)
        if(status === 200){
          result = data.list.filter(item=>!!item)
          cb(result)
        }else{
          cb(result)
        }
      },
      /*
      * 选择
      * */
      handleSelect(item){
        this.$emit('input',item[this.itemKey])
      },
      emitValue(val){
        this.$emit('input',val)
      },
    }
  }
</script>

<style scoped>

</style>
